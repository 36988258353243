.a-flag {
    display: flex;
    margin-bottom: $mu025;

    &__item {
        @include set-font-weight('semi-bold');
        @include set-font-scale('04', 'l');
        @include set-border-radius('s', 'left');
        display: inline-block;
        margin: 0;
        vertical-align: middle;
        border: 1px solid transparent;
        color: $color-grey-999;
        background-color: transparent;
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;

        &.-red {
            color: $color-grey-000;
            border-color: $color-danger-600;
            background-color: $color-danger-600;
        }

        &.-redBordered {
            color: $color-danger-600;
            border-color: $color-danger-600;
            background-color: $color-grey-000;
        }

        &.-darkBordered {
            color: $color-grey-999;
            border-color: $color-grey-999;
            background-color: $color-grey-000;
        }

        &.-red.-clearance {
            color: $color-secondary-yellow-300;
        }

        &.-redBordered.-clearance {
            text-transform: uppercase;
        }

        &.-firstPrice {
            @include set-font-weight('regular');
            border-color: $color-secondary-yellow-300;
            background-color: $color-secondary-yellow-300;
        }

        &.-highQuality {
            @include set-font-weight('regular');
            border-color: $color-secondary-blue-500;
            background-color: $color-secondary-blue-500;
        }

        &.-starProduct {
            @include set-font-weight('regular');
            border-color: $color-secondary-red-600;
            background-color: $color-secondary-red-600;
        }

        &.-refurbished {
            @include set-font-weight('regular');
            color: $color-grey-000;
            border-color: $color-info-600;
            background-color: $color-info-600;
        }

        &.-thirdParty {
            @include set-font-weight('regular');
            color: $color-grey-000;
            border-color: $color-grey-999;
            background-color: $color-grey-999;
        }

        &.-new {
            @include set-font-weight('regular');
            color: $color-grey-000;
            border-color: #643485;
            background-color: #643485;
        }

        &.-responsible {
            @include set-font-weight('regular');
            color: $color-secondary-green-600;
            border-color: $color-secondary-green-600;
        }

        &.-bestValue {
            @include set-font-weight('regular');
            border-color: $color-primary-01-600;
            background-color: $color-primary-01-600;
        }
    }

    &__label {
      -webkit-box-decoration-break: clone;
      box-decoration-break: clone;
      padding: $mu025 $mu050;

    }

    &__discount {
        white-space: nowrap;
        align-content: center;
    }

    .a-flag__item:only-child {
      @include set-border-radius('s');
    }

    .a-flag__item + .a-flag__item {
      @include set-border-radius('s', 'right');
    }
}
